import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import NavBar from "./commonComponent/Drawer/NavBar";
import Loader from "./commonComponent/Loader/Loader";

const Home = lazy(() => import("./screens/Home"));
const Login = lazy(() => import("./screens/Login"));
const PoListing = lazy(() => import('./screens/PoListing'))
const ReceivePo = lazy(() => import('./screens/PoListing/components/ReceivePo'))
const EditPo = lazy(() => import('./screens/PoListing/components/EditPo'))
const Invoice = lazy(() => import("./screens/PoListing/components/Invoice"));
const ReturnPo = lazy(() => import("./screens/PoListing/components/PoReturn "));
const GRN = lazy(() => import("./screens/PoListing/components/GRN"));
const SendPo = lazy(() => import("./screens/SendPo"));
const MultiplePoDemand = lazy(() => import("./screens/UploadPoExcel"));
const SendRipeningPo = lazy(() => import("./screens/SendRipeningPo"));
const Supplier = lazy(() => import("./screens/Supplier"));
const AddEditSupplier = lazy(() => import("./screens/Supplier/components/AddEditSupplier"));
const SupplierOnboarding = lazy(() => import("./screens/SupplierOnboarding"));
const ProductQualityRange = lazy(() => import("./screens/ProductQualityRange"))
const ProductPoDetail = lazy(() => import("./screens/ProductPoDetail"))

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/login" element={<Login />}></Route> */}
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        ></Route>
         <Route
          path="/users/distributors/supplier/details/v1/:id/"
          element={
            <Suspense fallback={<Loader />}>
              <SupplierOnboarding />
            </Suspense>
          }
        ></Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<WithNavbar />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            ></Route>
            <Route
              path={`/po-grn/:slotId/`}
              element={
                <Suspense fallback={<Loader />}>
                  <PoListing />
                </Suspense>
              }
            ></Route>
            <Route
              path="/receive-po/:slotId/:poId/"
              element={
                <Suspense fallback={<Loader />}>
                  <ReceivePo />
                </Suspense>
              }
            ></Route>
            <Route
              path="/edit-po/:slotId/:poId/"
              element={
                <Suspense fallback={<Loader />}>
                  <EditPo />
                </Suspense>
              }
            ></Route>
            <Route
              path="/invoice-po/:slotId/:poId/"
              element={
                <Suspense fallback={<Loader />}>
                  <Invoice />
                </Suspense>
              }
            ></Route>
            <Route
              path="/return-po/:slotId/:poId/"
              element={
                <Suspense fallback={<Loader />}>
                  <ReturnPo />
                </Suspense>
              }
            ></Route>
            <Route
              path="/grn/:slotId/:poId/"
              element={
                <Suspense fallback={<Loader />}>
                  <GRN />
                </Suspense>
              }
            ></Route>
            <Route
              path="/send-po/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <SendPo />
                </Suspense>
              }
            ></Route>
            <Route
              path="/send-po-excel/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <MultiplePoDemand />
                </Suspense>
              }
            ></Route>
            <Route
              path="/send-po-ripening/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <SendRipeningPo />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product-po-detail/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductPoDetail />
                </Suspense>
              }
            ></Route>
            <Route
              path="/supplier/"
              element={
                <Suspense fallback={<Loader />}>
                  <Supplier />
                </Suspense>
              }
            ></Route>
            <Route
              path="/supplier/:status/:id/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddEditSupplier />
                </Suspense>
              }
            ></Route>
            <Route
              path="/product-quality-range/"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductQualityRange />
                </Suspense>
              }
            ></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
