import Home from "../../icons/home-icon.png";
import PoGrn from "../../icons/po-grn-list1.svg";
import CreateIcon from "../../icons/create.svg";
import UserIcon from "../../icons/user-white.svg";
import ItemsIcon from "../../icons/quality-white.png"
import RipeningIcon from "../../icons/ripening-icon.svg"
import MangoIcon from "../../icons/fruits.svg"

export const SidebarData = [
  {
    title: "HOME PAGE",
    path: "/",
    getSlot: false,
    cName: "nav-item",
    image: Home,
  },
  {
    title: "PO & GRN",
    path: "/po-grn/",
    getSlot: true,
    cName: "nav-item",
    image: PoGrn,
  },
  {
    title: "GENERATE PO",
    path: "/send-po/",
    getSlot: true,
    cName: "nav-item",
    image: CreateIcon,
  },
  {
    title: "GENERATE MULTIPLE PO",
    path: "/send-po-excel/",
    getSlot: true,
    cName: "nav-item",
    image: CreateIcon,
  }, 
  {
    title: "RIPENING STOCK TRANSFER",
    path: "/send-po-ripening/",
    getSlot: true,
    cName: "nav-item",
    image: RipeningIcon,
  }, 
  {
    title: "PRODUCT PO DETAILS",
    path: "/product-po-detail/",
    getSlot: true,
    cName: "nav-item",
    image: MangoIcon,
  }, 
  {
    title: "SUPPLIER",
    path: "/supplier/",
    getSlot: false,
    cName: "nav-item",
    image: UserIcon,
  },
  {
    title: "GOODS QUALITY",
    path: "/product-quality-range/",
    getSlot: false,
    cName: "nav-item",
    image: ItemsIcon,
  },
];
