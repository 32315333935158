import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "../../icons/hamgrey.svg";
import ArrowLeft from "../../icons/arrow-left-2.png";
import CloseIcon from "../../icons/close.png";
import { SidebarData } from "./SidebarData";
import "./NavBar.css";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";

function NavBar(props) {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const navigation = useNavigate();
  const logout = () => {
    console.log("logout");
    localStorage.clear();
    navigation("/login");
  };
  const handleLinkTo = (item) => {
    let path = item.path 
    if(item.getSlot){
      let slot = localStorage.getItem('dc_date')
      path += slot + '/'
    }
    navigation(path)
    showSidebar()
  }
  return (
    
    <div className="navContainer">
      <div className="navbar">
        <Link to="#">
          <img
            src={MenuIcon}
            onClick={showSidebar}
            className="iconDrawer"
          />
        </Link>
        {/* <Link to="/">
          <img
            src={ArrowLeft}
            className="iconBack"
          />
        </Link> */}
      </div>
      <nav className={sidebar ? "navMenu active" : "navMenu"}>
        <ul>
          <img
            src={CloseIcon}
            onClick={showSidebar}
            style={{ width: "2.1rem", marginBottom: "0.8rem" }}
            className={'closeBtn'}
          />
          <div className="userDetail">
            <div className="fontL capital">{localStorage.getItem("name")}</div>
            <div className="fontM">{localStorage.getItem("ename")}</div>
          </div>
          {/* user details  */}
          {SidebarData.map((item, index) => {
            return (
              <span onClick={() => handleLinkTo(item)} key={index}>
                <li key={index} className={item.cName}>
                  <img
                    src={item.image}
                    style={{
                      width: "1.5rem",
                      marginRight: "0.6rem",
                      // marginBottom: "-0.3rem",
                      marginLeft: "0.3rem",
                    }}
                  />
                  <span> {item.title} </span>
                </li>
              </span>
            );
          })}
        </ul>
        <button className="logoutBtn" onClick={logout}>
          <LogoutIcon className="logoutIcon" /> logout
        </button>
      </nav>
      <div
        onClick={showSidebar}
        className={sidebar ? "dummyDiv active" : "dummyDiv"}
      ></div>
    </div>
  );
}

export default NavBar;
